exports.install = function (Vue) {
	// 传入融合后的数据  当前选中的武器类型 人物等级
	Vue.prototype.dataConsolidation = function (list, arms, roleLevel, legendaryLevel = 0, editions) {
		let dataIntegration = {
			label: {}
		}

		// 力量数据
		dataIntegration.power = parseInt(list.power * ((list.powerPercentage + list.baseAttributePercentage) / 100 + 1) + list.extraStrength)
		// 体质数据
		dataIntegration.constitution = parseInt(list.constitution * ((list.constitutionPercentage + list.baseAttributePercentage) / 100 + 1) + list.extraConstitution)
		// 敏捷数据
		dataIntegration.agile = parseInt(list.agile * ((list.agilePercentage + list.baseAttributePercentage) / 100 + 1) + list.extraAgility)
		// 智力数据
		dataIntegration.intelligence = parseInt(list.intelligence * ((list.intelligencePercentage + list.baseAttributePercentage) / 100 + 1) + list.extraIntelligence)
		// 感知数据
		dataIntegration.perception = parseInt(list.perception * ((list.perceptionPercentage + list.baseAttributePercentage) / 100 + 1) + list.extraPerception)
		// 角色等级提升所附加的基础数据
		let basicsH = 0
		let basicsP = 0
		for (let i = 1; i <= (roleLevel + legendaryLevel); i++) {
			basicsH += 40
			basicsP += 10
		}
		// 角色生命值----角色初始60生命
		dataIntegration.basicHP = parseInt((dataIntegration.power * 3 + parseInt(dataIntegration.constitution * 6.5) + list.basicHP + basicsH + 60) * (list.bloodVolumePercentage / 100 + 1))
		// 角色法力值----角色初始40法力
		dataIntegration.mana = parseInt((parseInt(dataIntegration.perception * 3.5) + parseInt(dataIntegration.intelligence * 7.5) + basicsP + 40 + list.mana) * (list.manaPercentage / 100 + 1))
		// 出手速度
		dataIntegration.move = parseInt((list.move + dataIntegration.agile * 3 + parseInt(dataIntegration.perception * 1.5)) * (list.movePercentage / 100 + 1))
		// 武器判定---只有有单手武器的装备才需要判定
		// 刀
		if (arms === "light-sabre" || arms === "sabre" || arms === "light_sabre") {
			arms = "sabre"
		}
		// 剑
		if (arms === "light-sword" || arms === "sword" || arms === "light_sword") {
			arms = "sword"
		}
		// 弩
		if (arms === "light-long-range" || arms === "long-range" || arms === "arch-long-range" || arms === "light_long_range" || arms === "arch_long_range" || arms === "long_range") {
			arms = "long_range"
		}
		// 火枪
		if (arms === "light-artillery" || arms === "artillery" || arms === "light_artillery") {
			arms = "artillery"
		}
		// 空手
		if (arms === "empty-handed") {
			arms = "empty_handed"
		}
		// 锤类
		if (arms === "light_hammer" || arms === "hammer") {
			arms = "hammer"
		}
		// 武器伤害范围
		// 最低武器伤害
		dataIntegration.minimumWeaponDamage = parseInt(list.minimumWeaponDamage * ((list[arms + "WeaponDamage"] + list.weaponDamage) / 100 + 1))
		// 最高武器伤害
		dataIntegration.maximumWeaponDamage = parseInt(list.maximumWeaponDamage * ((list[arms + "WeaponDamage"] + list.weaponDamage) / 100 + 1))
		// 武商拦截,如果武商为NaN则强制为0
		if (Object.is(dataIntegration.minimumWeaponDamage, NaN) || Object.is(dataIntegration.maximumWeaponDamage, NaN)) {
			dataIntegration.minimumWeaponDamage = 0
			dataIntegration.maximumWeaponDamage = 0
		}
		// 武器攻击次数
		if (list[arms + "AttackTimes"]) {
			dataIntegration[arms + "AttackTimes"] = list[arms + "AttackTimes"]
		}
		// 全攻次
		dataIntegration.attackTimes = (list.attackTimes > 0 ? list.attackTimes : 0)
		// 空手情况下默认1攻次
		if (arms === "empty-handed" || arms === "empty_handed") {
			dataIntegration.attackTimes += 1
		}
		// 把攻次统计到一个变量接收,方便后续操作
		let attackTimes = dataIntegration[arms + "AttackTimes"] + dataIntegration.attackTimes
		// 攻次检测
		if (Object.is(attackTimes, NaN)) {
			attackTimes = dataIntegration.attackTimes
		}
		// 攻次最低默认1
		dataIntegration.attack = attackTimes > 0 ? attackTimes : 1

		// 判断武伤数据是否为空手,空手状态下会加成物理攻击所以劈砍/穿刺攻击攻击力需要和其他职业分开
		// 空手职业比较特殊,不会受到全武商的加成
		if (arms === "empty-handed" || arms === "empty_handed") {
			// 劈砍攻击力
			dataIntegration.basicChopAttack = parseInt((list.basicChopAttack + dataIntegration.power * 5 + dataIntegration.agile * 3))
			// 穿刺攻击力
			dataIntegration.basicPuncture = parseInt((list.basicPuncture + dataIntegration.power * 5 + dataIntegration.agile * 5))
			// 空手没有武器伤害
			dataIntegration.minimumWeaponDamage = 0
			dataIntegration.maximumWeaponDamage = 0
			dataIntegration.basicChopAttack = parseInt(dataIntegration.basicChopAttack * ((list[arms + "WeaponDamage"] + list.chopAttackPercentage) / 100 + 1))
			dataIntegration.basicPuncture = parseInt(dataIntegration.basicPuncture * ((list[arms + "WeaponDamage"] + list.puncturePercentage) / 100 + 1))
		} else {
			// 劈砍攻击力
			dataIntegration.basicChopAttack = parseInt((list.basicChopAttack + dataIntegration.power * 5 + dataIntegration.agile * 3) * (list.chopAttackPercentage / 100 + 1))
			// 穿刺攻击力
			dataIntegration.basicPuncture = parseInt((list.basicPuncture + dataIntegration.power * 5 + dataIntegration.agile * 5) * (list.puncturePercentage / 100 + 1))
		}
		// // 攻击力最高100w
		// dataIntegration.basicChopAttack = dataIntegration.basicChopAttack > 1000000 ? 1000000 : dataIntegration.basicChopAttack
		// // 攻击力最高100w
		// dataIntegration.basicPuncture = dataIntegration.basicPuncture > 1000000 ? 1000000 : dataIntegration.basicPuncture
		// 魔法命中----角色初始100魔命
		dataIntegration.magicHit = parseInt((100 + list.magicHit) * (list.magicHitPercentage / 100 + 1))
		// 魔攻百分比加成
		dataIntegration.magicPercentage = list.magicPercentage
		// 魔法攻击力
		dataIntegration.basicMagic = parseInt(parseInt((dataIntegration.intelligence * 3 + dataIntegration.perception * 2) * ((dataIntegration.magicHit - 100) / 1000 + 1) + list.basicMagic) * (list.magicPercentage / 100 + 1))
		// // 攻击力最高100w
		// dataIntegration.basicMagic = dataIntegration.basicMagic > 1000000 ? 1000000 : dataIntegration.basicMagic
		// 劈砍防御力
		dataIntegration.chopDefense = parseInt((list.chopDefense + dataIntegration.constitution * 8) * (list.chopDefensePercentage / 100 + 1))
		// 穿刺防御力
		dataIntegration.punctureDefense = parseInt((list.punctureDefense + dataIntegration.constitution * 8) * (list.punctureDefensePercentage / 100 + 1))
		// 魔法防御力
		dataIntegration.magicDefense = parseInt((list.magicDefense + dataIntegration.intelligence * 2 + dataIntegration.perception * 4) * (list.magicDefensePercentage / 100 + 1))
		// 法力护盾
		dataIntegration.manaShield = parseInt((list.manaShield + dataIntegration.perception * 3 + dataIntegration.intelligence * 2) * (list.manaShieldPercentage / 100 + 1))
		// 物理命中
		dataIntegration.hit = parseInt(parseInt(list.hit + dataIntegration.agile * 0.2) * (list.hitPercentage / 100 + 1))
		// 强制命中
		dataIntegration.forcedHit = (list.forcedHit + 30) > 70 ? 70 : (list.forcedHit + 30)
		// 破甲
		dataIntegration.armourBreaking = parseInt((list.armourBreaking + dataIntegration.power * 3 + dataIntegration.agile) * (list.armourBreakingPercentage / 100 + 1))
		// 魔法穿透
		dataIntegration.magicPenetration = parseInt((list.magicPenetration + dataIntegration.intelligence * 2 + dataIntegration.perception) * (list.magicPenetrationPercentage / 100 + 1))
		// 致命点
		dataIntegration.deadly = parseInt(list.deadly * (list.deadlyPercentage / 100 + 1))
		// 免伤护盾
		dataIntegration.immuneShield = list.immuneShield
		// 状态抵抗
		dataIntegration.state = parseInt((list.state + parseInt(dataIntegration.perception * 0.3 + dataIntegration.intelligence * 0.1)) * (list.statePercentage / 100 + 1))
		// 洞察
		dataIntegration.insight = parseInt((list.insight + parseInt(dataIntegration.perception * 0.2 + dataIntegration.intelligence * 0.1)) * (list.insightPercentage / 100 + 1))
		// 格挡
		dataIntegration.block = parseInt(parseInt(list.block + dataIntegration.agile * 0.2) * (list.blockPercentage / 100 + 1))
		// 伤害减免
		dataIntegration.damageReduction = parseInt((list.damageReduction + parseInt(dataIntegration.power * 0.1 + dataIntegration.constitution * 0.2)) * (list.damageReductionPercentage / 100 + 1))
		// 闪避
		dataIntegration.dodge = parseInt(parseInt(list.dodge + (dataIntegration.agile + dataIntegration.perception) * 0.4) * (list.dodgePercentage / 100 + 1))
		// 反击
		dataIntegration.counterAttack = parseInt(list.counterAttack * (list.counterAttackPercentage / 100 + 1))
		// 魔法反馈
		dataIntegration.magicFeedback = parseInt(list.magicFeedback * (list.magicFeedbackPercentage / 100 + 1))
		// 生命吸收
		dataIntegration.lifeAbsorption = parseInt(list.lifeAbsorption * (list.lifeAbsorptionPercentage / 100 + 1))
		// 法力吸收
		dataIntegration.manaAbsorption = parseInt(list.manaAbsorption * (list.manaAbsorptionPercentage / 100 + 1))
		// 自动恢复生命
		dataIntegration.HPRecovery = parseInt(list.HPRecovery * (list.HPRecoveryPercentage / 100 + 1))
		// 自动恢复法力
		dataIntegration.manaRegeneration = parseInt(list.manaRegeneration * (list.manaRegenerationPercentage / 100 + 1))
		// 无视反击
		dataIntegration.ignorecounterAttack = list.ignorecounterAttack
		// 无视魔法反馈
		dataIntegration.ignoreMagicFeedback = list.ignoreMagicFeedback
		// 无视格挡
		dataIntegration.ignoreBlock = list.ignoreBlock
		// 无视洞察
		dataIntegration.ignoreInsight = list.ignoreInsight
		// 无视状态抵抗
		dataIntegration.disregardState = list.disregardState
		// 无视伤害减免
		dataIntegration.ignoreDamageReduction = list.ignoreDamageReduction


		// 协防数据
		// 人物向-宠物承受伤害(%)
		dataIntegration.pets_SufferDamage = list.pets_SufferDamage
		// 人物向-宠物承受穿刺伤害(%)
		dataIntegration.pets_SufferPunctureDamage = list.pets_SufferPunctureDamage
		// 人物向-宠物承受劈砍伤害(%)
		dataIntegration.pets_SufferChopDamage = list.pets_SufferChopDamage
		// 人物向-宠物承受魔法伤害(%)
		dataIntegration.pets_SufferMagicDamage = list.pets_SufferMagicDamage
		// 人物向-宠物承受诅咒效果(%)
		dataIntegration.pets_SufferCurseDamage = list.pets_SufferCurseDamage

		// 宠物承受伤害(%)
		dataIntegration.petsSufferDamage = list.petsSufferDamage
		// 宠物承受穿刺伤害(%)
		dataIntegration.petsSufferPunctureDamage = list.petsSufferPunctureDamage
		// 宠物承受劈砍伤害(%)
		dataIntegration.petsSufferChopDamage = list.petsSufferChopDamage
		// 宠物承受魔法伤害(%)
		dataIntegration.petsSufferMagicDamage = list.petsSufferMagicDamage
		// 宠物承受诅咒效果(%)
		dataIntegration.petsSufferCurseDamage = list.petsSufferCurseDamage

		// 任务经验
		dataIntegration.taskExperience = list.taskExperience
		// 战斗经验
		dataIntegration.combatExperience = list.combatExperience

		/* 劈砍攻击力： 0.03  穿刺攻击力： 0.03 魔法攻击力： 0.01 物理命中： 0.04 强制命中率： 5 魔法命中： 0.16 破甲： 0.06 魔法穿透： 0.06 劈砍防御力： 0.03 穿刺防御力： 0.03 魔法防御力： 0.01 格挡： 0.14 反击： 0.28 魔法反馈： 0.28 出手速度： 0.04 闪避： 0.2 洞察： 0.56 状态抵抗： 0.5 法力护盾： 0.05 伤害减免： 0.56 免伤护盾： 100 生命值： 0.003 法力值： 0.003 生命吸收： 0.075 法力吸收： 0.075 自动恢复生命： 0.05 自动恢复法力： 0.05 人物等级： 2 人物传奇等级： 10 当前最高武器伤害 * 当前攻击次数： 0.05 */

		if (editions === "小程序") {
			dataIntegration.fightingCapacity = Math.floor((roleLevel * 2 + dataIntegration.basicHP * 0.003 + dataIntegration.mana * 0.003 + legendaryLevel * 10 + dataIntegration.move * 0.04 + dataIntegration.maximumWeaponDamage * dataIntegration.attack * 0.05 + dataIntegration.basicChopAttack * 0.03 + dataIntegration.basicPuncture * 0.03 + dataIntegration.basicMagic * 0.01 + dataIntegration.chopDefense * 0.03 + dataIntegration.punctureDefense * 0.03 + dataIntegration.magicDefense * 0.01 + dataIntegration.dodge * 0.2 + dataIntegration.hit * 0.04 + dataIntegration.magicHit * 0.16 + dataIntegration.state * 0.5 + dataIntegration.damageReduction * 0.56 + dataIntegration.block * 0.14 + dataIntegration.armourBreaking * 0.06 + dataIntegration.insight * 0.56 + dataIntegration.manaShield * 0.05 + dataIntegration.counterAttack * 0.28 + dataIntegration.magicFeedback * 0.28 + dataIntegration.lifeAbsorption * 0.075 + dataIntegration.manaAbsorption * 0.075 + dataIntegration.magicPenetration * 0.06 + dataIntegration.forcedHit * 5 + dataIntegration.HPRecovery * 0.05 + dataIntegration.manaRegeneration * 0.05 + dataIntegration.immuneShield * 100) * 20)
		} else {
			dataIntegration.fightingCapacity = Math.floor(roleLevel * 2 + dataIntegration.basicHP * 0.003 + dataIntegration.mana * 0.003 + legendaryLevel * 10 + dataIntegration.move * 0.04 + dataIntegration.maximumWeaponDamage * dataIntegration.attack * 0.05 + dataIntegration.basicChopAttack * 0.03 + dataIntegration.basicPuncture * 0.03 + dataIntegration.basicMagic * 0.01 + dataIntegration.chopDefense * 0.03 + dataIntegration.punctureDefense * 0.03 + dataIntegration.magicDefense * 0.01 + dataIntegration.dodge * 0.2 + dataIntegration.hit * 0.04 + dataIntegration.magicHit * 0.16 + dataIntegration.state * 0.5 + dataIntegration.damageReduction * 0.56 + dataIntegration.block * 0.14 + dataIntegration.armourBreaking * 0.06 + dataIntegration.insight * 0.56 + dataIntegration.manaShield * 0.05 + dataIntegration.counterAttack * 0.28 + dataIntegration.magicFeedback * 0.28 + dataIntegration.lifeAbsorption * 0.075 + dataIntegration.manaAbsorption * 0.075 + dataIntegration.magicPenetration * 0.06 + dataIntegration.forcedHit * 5 + dataIntegration.HPRecovery * 0.05 + dataIntegration.manaRegeneration * 0.05 + dataIntegration.immuneShield * 100)
		}

		return dataIntegration
	}
}