exports.install = function (Vue) {
  Vue.prototype.attributeCalculation = function (deduction, skillAttribute, characteristic) {
    // 本页面使用的都是对象深拷贝

    // 属性总值
    // console.log(deduction);
    // 扣除技能
    // console.log(skillAttribute);
    // 副本是否有回合回复效果
    // console.log(characteristic);

    // 数据叠加保存的数组
    let list = []

    // 百万攻击力
    let millionAttackPower = 1000000

    // 当前回合属性初始值
    let currentData = []

    // 当前回合叠加后的数据
    let attributeArry = []

    // 备份数据,后面通过备份的数据计算
    let backupData = []
    // 对传过来的数据先做个备份
    let copySkillAttribute = []
    // 通过json转义达到对象深拷贝的效果==>不是引用地址值而是拿到具体数据
    skillAttribute.filter(item => backupData.push(JSON.parse(JSON.stringify(item))))
    skillAttribute.filter(item => copySkillAttribute.push(JSON.parse(JSON.stringify(item))))

    // 技能过期回合
    let overdue = 0
    // 技能过期值
    let overdueValue = 0
    // 技能属性叠加数组
    let attributeDeductionArry = []
    let attributeDeduction = []

    for (let i = 1; i <= 30; i++) {
      // 当前回合初始值
      currentData.push(deduction)

      // 从第二回合开始,向传递过来的属性值里面填充备份的数据==>模拟打副本时技能效果叠加
      if (i > 1) {
        copySkillAttribute.filter(item => {
          // 单开模式每回合增加数据
          if (!item.mode) {
            backupData.push(JSON.parse(JSON.stringify(item)))
          }
          // 混开模式单回合增加数据
          if (item.mode) {
            if ((!item.doubleRound && i % 2 !== 0) || (item.doubleRound && i % 2 === 0)) {
              backupData.push(JSON.parse(JSON.stringify(item)))
            } else {
              // 混开模式在双回合不会有数据填充,因此,扣除值也应该为0
              // 但是为了防止扣除值数据被打乱,这里填充一个扣除0%的数据值,持续一回合不会影响数据
              backupData.push({
                mode: true,
                additional: 0,
                round: 1,
                value: 0
              })
            }
          }
        })
      }

      // 遍历传递过来的技能属性值
      backupData.filter((item) => {
        // 属性过期回合分配
        if (!item.overdue) {
          item.overdue = parseInt(item.round) + i - 1
          // 单回合数据使用
          if (item.round === 1) {
            // (item.additional || 0) 防止用户误删技能基础属性扣除值
            let deductionData = parseInt((item.value / 100) * deduction) + (item.additional || 0)
            deduction += deductionData
            overdue = item.overdue
            attributeDeductionArry.push({ deductionData, overdue })
          } else {
            // 单开模式的数据
            if (!item.mode) {
              let deductionData = parseInt((item.value / 100) * deduction) + (item.additional || 0)
              deduction += deductionData
              overdue = item.overdue
              attributeDeductionArry.push({ deductionData, overdue })
            } else
              // 混开模式的数据
              if (item.mode && (!item.doubleRound && i % 2 !== 0) || (item.doubleRound && i % 2 === 0)) {
                let deductionData = parseInt((item.value / 100) * deduction) + (item.additional || 0)
                deduction += deductionData
                overdue = item.overdue
                attributeDeductionArry.push({ deductionData, overdue })
              } else {
                attributeDeductionArry.push({ deductionData: 0, overdue: 0 })
              }
          }
        }
      })

      // 对最大属性进行规范
      deduction = millionAttackPower > deduction ? deduction : millionAttackPower
      // 将叠加后的数据值保留到数组中 
      attributeArry.push(deduction)

      // 技能回合过期
      deduction -= skillOverdue(i)

      if (deduction === millionAttackPower) {
        break
      }
    }

    let index = 1
    let arryLength = skillAttribute.length
    for (let j = 0, len = attributeDeductionArry.length; j < len; j++) {
      attributeDeduction.push(`叠加技能${index}叠加的属性为:${attributeDeductionArry[j].deductionData}`)
      index++
      if (index > arryLength) index = 1
    }
    attributeDeduction = againGroup(attributeDeduction, arryLength)
    for (let j = 1; j <= 30; j++) {
      list.push({
        round: `第【${j}】回合`,
        currentData: `当前回合初始值为:${currentData[j - 1]}`,
        dunkSkills: attributeDeduction[j - 1],
        basicData: `回合结束(叠加后)值为:${attributeArry[j - 1]}`,
        superpositions: `技能属性过期值为:${skillOverdue(j)}`
      })
      if (attributeArry[j - 1] === millionAttackPower) {
        break
      }
    }
    return list

    // 切割数组
    function againGroup(data, num) {
      var result = [];
      for (var i = 0, len = data.length; i < len; i += num) {
        result.push(data.slice(i, i + num));
      }
      return result;
    }

    // 技能属性过期
    function skillOverdue(index) {
      overdueValue = 0
      attributeDeductionArry.filter(item => {
        if (item.overdue === index) {
          overdueValue += item.deductionData
        }
      })
      return overdueValue
    }
  }
}