import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: "/",
  //   redirect: { name: "PC端首页" }
  // },
  {
    path: '/windows-home',
    name: 'PC端首页',
    component: () =>
      import("@/views/Windows/MyHome.vue")
  },
  {
    path: "/windows-EnchantStack",
    name: "魔化堆叠",
    component: () =>
      import("@/views/Windows/EnchantStack.vue")
  },
  {
    path: "/windows-DemonizedBattle",
    name: "魔化战斗堆叠",
    component: () =>
      import("@/views/Windows/DemonizedBattle.vue")
  },
  {
    path: "/windows-MageStack",
    name: "法师自动堆叠",
    component: () =>
      import("@/views/Windows/MageStack.vue")
  },
  {
    path: "/windows-CopyRoundEstimation",
    name: "副本回合估算",
    component: () =>
      import("@/views/Windows/CopyRoundEstimation.vue")
  },
  {
    path: "/windows-AttributeCalculation",
    name: "人物属性计算",
    component: () =>
      import("@/views/Windows/AttributeCalculation.vue"),
  },
  {
    path: "/windows-PlayList",
    name: "玩法推荐",
    meta: {
      keepAlive: true,
      isNeedRefresh: true
    },
    component: () =>
      import("@/views/Windows/PlayList.vue"),
  },
  {
    path: "/windows-LocalFavorites",
    name: "本地收藏夹",
    meta: {
      keepAlive: true,
      isNeedRefresh: true
    },
    component: () =>
      import("@/views/Windows/LocalFavorites.vue")
  },
  {
    path: "/windows-PetCalculation",
    name: "宠物属性计算",
    component: () =>
      import("@/views/Windows/PetCalculation.vue")
  },
  {
    path: "/windows-DataDisplay",
    name: "数据展示",
    component: () =>
      import("@/views/Windows/DataDisplay.vue")
  },
  {
    path: "/windows-WeaponDamageFloat",
    name: "武商浮动计算",
    component: () =>
      import("@/views/Windows/WeaponDamageFloat.vue")
  },
  {
    path: "/windows-BrushInjury",
    name: "刷伤害计算",
    component: () =>
      import("@/views/Windows/BrushInjury.vue")
  },
  {
    path: "/windows-ArticleDisplay",
    name: "文档攻略",
    meta: {
      keepAlive: true,
      isNeedRefresh: true
    },
    component: () =>
      import("@/views/Windows/ArticleDisplay.vue")
  },
  {
    path: "/windows-Article",
    name: "文章展示",
    component: () =>
      import("@/components/windows/Article.vue")
  },
  {
    path: "/windows-IllustratedHandbook",
    name: "图鉴数据",
    meta: {
      keepAlive: true,
      isNeedRefresh: true
    },
    component: () =>
      import("@/views/Windows/IllustratedHandbook.vue")
  },
  {
    path: "/windows-MonsterDetails",
    name: "怪物详情",
    component: () =>
      import("@/components/windows/MonsterDetails.vue")
  },
  {
    path: "/windows-PetDetails",
    name: "宠物详情",
    component: () =>
      import("@/components/windows/PetDetails.vue")
  },
  {
    path: "/windows-WeaponEquipmentDetails",
    name: "装备详情",
    component: () =>
      import("@/components/windows/WeaponEquipmentDetails.vue")
  },
  {
    path: "/windows-SkillAttribute",
    name: "技能属性计算",
    component: () =>
      import("@/views/Windows/SkillAttribute.vue")
  },
  {
    path: "/windows-DataCalculation",
    name: "数据计算",
    component: () =>
      import("@/views/Windows/DataCalculation.vue")
  },
  {
    path: "/windows-AuthorURI",
    name: "作者主页",
    meta: {
      keepAlive: true,
      isNeedRefresh: true
    },
    component: () =>
      import("@/views/Windows/AuthorURI.vue")
  },
  {
    path: "/windows-PlayRecommended",
    name: "玩法展示",
    component: () =>
      import("@/components/windows/PlayRecommended.vue")
  },
  {
    path: "/windows-DataUpload",
    name: "数据上传",
    component: () =>
      import("@/views/Windows/DataUpload.vue")
  },
  {
    path: "/windows-GamesIntroduction",
    name: "副本简介",
    meta: {
      keepAlive: true,
      isNeedRefresh: true
    },
    component: () =>
      import("@/views/Windows/GamesIntroduction.vue")
  },
  {
    path: "/windows-GamesDetails",
    name: "副本详情",
    component: () =>
      import("@/components/windows/GamesDetails.vue")
  },
  {
    path: "/windows-ToolIntroduction",
    name: "网站介绍",
    component: () =>
      import("@/views/Windows/ToolIntroduction.vue")
  },
  {
    path: "/windows-CustomEquipmentTemplate",
    name: "自定义装备模版",
    component: () =>
      import("@/views/Windows/CustomEquipmentTemplate.vue")

  },
  {
    path: "/windows-EquipmentTemplate",
    name: "新增自定义装备",
    component: () =>
      import("@/components/windows/EquipmentTemplate.vue")
  },
  {
    path: "/android-home",
    name: "移动端首页",
    component: () =>
      import("@/views/Android/MyHome.vue"),
    meta: {
      TabbarShow: true // 需要显示 底部导航
    }
  },
  {
    path: "/android-WebsiteTools",
    name: "网站工具",
    // 路由从定向到默认子路由
    redirect: "/android-WebsiteTools",
    component: () =>
      import("@/views/Android/WebsiteTools.vue"),
    meta: {
      TabbarShow: true // 需要显示 底部导航
    },
    children: [{
      path: "/",
      component: () =>
        import("@/views/Android/ToolsLists.vue"),
      meta: {
        TabbarShow: true // 需要显示 底部导航
      }
    },
    {
      path: "/android-ToolsLists",
      name: "工具列表",
      component: () =>
        import("@/views/Android/ToolsLists.vue")
    },
    {
      path: "/android-EnchantStack",
      name: "安卓-魔化堆叠",
      component: () =>
        import("@/views/Android/EnchantStack.vue")
    },
    {
      path: "/android-DemonizedBattle",
      name: "安卓-魔化战斗堆叠",
      component: () =>
        import("@/views/Android/DemonizedBattle.vue")
    },
    {
      path: "/android-MageStack",
      name: "安卓-法师自动堆叠",
      component: () =>
        import("@/views/Android/MageStack.vue")
    },
    {
      path: "/android-CopyRoundEstimation",
      name: "安卓-副本回合估算",
      component: () =>
        import("@/views/Android/CopyRoundEstimation.vue")
    },
    {
      path: "/android-GradeSuppression",
      name: "安卓-等级压制",
      component: () =>
        import("@/views/Android/GradeSuppression.vue")
    },
    {
      path: "/android-SkillAttribute",
      name: "安卓-技能属性计算",
      component: () =>
        import("@/views/Android/SkillAttribute.vue")
    },
    {
      path: "/android-MonsterManual",
      name: "安卓-怪物图鉴",
      meta: {
        keepAlive: true,
        isNeedRefresh: true
      },
      component: () =>
        import("@/views/Android/MonsterManual.vue")
    },
    {
      path: "/android-PetGuide",
      name: "安卓-宠物图鉴",
      meta: {
        keepAlive: true,
        isNeedRefresh: true
      },
      component: () =>
        import("@/views/Android/PetGuide.vue")
    },
    {
      path: "/android-WeaponEquipmentAtlas",
      name: "安卓-装备图鉴",
      meta: {
        keepAlive: true,
        isNeedRefresh: true
      },
      component: () =>
        import("@/views/Android/WeaponEquipmentAtlas.vue")
    },
    {
      path: "/android-MonsterDetails",
      name: "安卓-怪物详情",
      component: () =>
        import("@/components/android/MonsterDetails.vue")
    },
    {
      path: "/android-PetDetails",
      name: "安卓-宠物详情",
      component: () =>
        import("@/components/android/PetDetails.vue")
    },
    {
      path: "/android-WeaponEquipmentDetails",
      name: "安卓-装备详情",
      component: () =>
        import("@/components/android/WeaponEquipmentDetails.vue")
    },
    {
      path: "/android-WeaponDamageFloat",
      name: "安卓-武商浮动计算",
      component: () =>
        import("@/views/Android/WeaponDamageFloat.vue")
    },
    {
      path: "/android-BrushInjury",
      name: "安卓-刷伤害计算",
      component: () =>
        import("@/views/Android/BrushInjury.vue")
    },
    {
      path: "/android-PlayList",
      name: "安卓-玩法推荐",
      meta: {
        keepAlive: true,
        isNeedRefresh: true
      },
      component: () =>
        import("@/views/Android/PlayList.vue")
    },
    {
      path: "/android-ArticleDisplay",
      name: "安卓-文档攻略",
      meta: {
        keepAlive: true,
        isNeedRefresh: true
      },
      component: () =>
        import("@/views/Android/ArticleDisplay.vue")
    },
    {
      path: "/android-AuthorURI",
      name: "安卓-作者主页",
      meta: {
        keepAlive: true,
        isNeedRefresh: true
      },
      component: () =>
        import("@/views/Android/AuthorURI.vue")
    },
    {
      path: "/android-Article",
      name: "安卓-文章展示",
      component: () =>
        import("@/components/android/Article.vue")
    },
    {
      path: "/android-LocalFavorites",
      name: "安卓-本地收藏夹",
      meta: {
        keepAlive: true,
        isNeedRefresh: true
      },
      component: () =>
        import("@/views/Android/LocalFavorites.vue")
    },
    {
      path: "/android-DataDisplay",
      name: "安卓-数据展示",
      meta: {
        keepAlive: true,
        isNeedRefresh: true
      },
      component: () =>
        import("@/views/Android/DataDisplay.vue")
    },
    {
      path: "/android-AttributeCalculation",
      name: "安卓-人物属性计算",
      component: () =>
        import("@/views/Android/AttributeCalculation.vue")
    },
    {
      path: "/android-PetCalculation",
      name: "安卓-宠物属性计算",
      component: () =>
        import("@/views/Android/PetCalculation.vue")
    },
    {
      path: "/android-DataUpload",
      name: "安卓-数据上传",
      component: () =>
        import("@/views/Android/DataUpload.vue")
    },
    {
      path: "/android-PlayRecommended",
      name: "安卓-玩法展示",
      component: () =>
        import("@/components/android/PlayRecommended.vue")
    },
    {
      path: "/android-GamesIntroduction",
      name: "安卓-副本简介",
      meta: {
        keepAlive: true,
        isNeedRefresh: true
      },
      component: () =>
        import("@/views/Android/GamesIntroduction.vue")
    },
    {
      path: "/android-GamesDetails",
      name: "安卓-副本详情",
      component: () =>
        import("@/components/android/GamesDetails.vue")
    },
    {
      path: "/android-AttackConversion",
      name: "安卓-武商攻次换算",
      component: () =>
        import("@/views/Android/AttackConversion.vue")
    },
    {
      path: "/android-ToolIntroduction",
      name: "安卓-网站工具介绍",
      component: () =>
        import("@/views/Android/ToolIntroduction.vue")
    }
    ]
  },
  {
    path: "/android-WebsiteInformation",
    name: "网站信息",
    component: () =>
      import("@/views/Android/WebsiteInFormation.vue"),
    meta: {
      TabbarShow: true // 需要显示 底部导航
    }
  },
]

const router = new VueRouter({
  routes,
  mode: "hash",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) { //如果savedPosition存在，滚动条会自动跳到记录的值的地方
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      } //savedPosition也是一个记录x轴和y轴位置的对象
    }
  },
})

// 移动端回到首页报错,方法重写
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}
export default router