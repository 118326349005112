import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 导入自定义方法
// 魔化叠加
import enchantment from "@/method/魔化叠加/enchantment"
// 魔化-战斗数据先行
import antecedent from "@/method/魔化-战斗/antecedent"
// 劈砍系金刚混战斗
import chopVajraBlendMethod from "@/method/魔化-战斗/金刚队伍/chopVajraBlendMethod"
// 法师自动叠加
import magicenhancement from "@/method/法师自动叠加/magicenhancement"
// 伤害计算
import InjuryCalculation from "@/method/伤害计算/InjuryCalculation"
// 回合回复伤害计算
import ContinuousReply from "@/method/伤害计算/回合回复伤害计算/ContinuousReply"
// 技能属性计算
import energyWithdrawal from "@/method/技能属性计算/energyWithdrawal"
import attributeCalculation from "@/method/技能属性计算/attributeCalculation"
import attributeExpirationCalculation from "@/method/技能属性计算/attributeExpirationCalculation"
import skillAttributeExpiration from "@/method/技能属性计算/skillAttributeExpiration"
// 等级压制数据计算
import gradeSuppression from "@/method/等级压制数据计算/gradeSuppression"
// 数据整合
import dataConsolidation from "@/method/数据整合/dataConsolidation"
// 宠物数据整合
import petDataConsolidation from "@/method/数据整合/宠物数据/petDataConsolidation"
// 数据对比
import DataComparison from "@/method/数据对比/DataComparison"
// 武伤攻次代换计算
import ConversionOfMartialDamageAttackTimes from "@/method/武伤攻次代换计算/ConversionOfMartialDamageAttackTimes"

// 全局导入Element UI包
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'default-passive-events'
//全局导入css
import "@/styles/index.css"
// 全局导入Vant UI包
import Vant from 'vant';
import 'vant/lib/index.css';

// 导入阿里字体图标
import "@/assets/阿里字体图标/iconfont.css"

// 懒加载
import { Lazyload } from 'vant';

// 复制内容到剪贴板
import VueClipboard from 'vue-clipboard2'

// 使用 vue-meta-info
import Meta from "vue-meta-info";
Vue.use(Meta);

// 将本地存储的核心挂载到vuex
import { get, set, update, createStore, keys } from 'idb-keyval';
const customStore = createStore('treefuture', 'attribute');
Vue.prototype.$customStore = customStore
Vue.prototype.$dataCalling = async (data) => {
  let { name } = data
  const key = await keys(customStore)
  if (key.some(item => item == name)) {
    return get(name, customStore).then((val) => { return val })
  } else {
    set(name, "", customStore)
      .then(() => console.log('It worked!'))
      .catch((err) => console.log('It failed!', err))
  }
}
Vue.prototype.$dataUpdates = (data) => {
  let { name, datas } = data
  update(name, (val) => datas, customStore)
}

// 全局导入axios包
import axios from "axios"

Vue.config.productionTip = false
// 挂载axios
Vue.prototype.$http = axios
// axios.defaults.baseURL = "http://localhost:8080"
// 请求拦截器
// axios.interceptors.request.use(config => {
//   console.log(config);
// })
// 响应拦截器
// axios.interceptors.response.use(config => {

// })

// 挂载一个全局方法,用于检测是移动端还是PC端
Vue.prototype.$equipment = function () {
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  return flag
}

// 挂载自定义方法
Vue.use(enchantment)
Vue.use(antecedent)
Vue.use(chopVajraBlendMethod)
Vue.use(magicenhancement)
Vue.use(InjuryCalculation)
Vue.use(ContinuousReply)
Vue.use(attributeCalculation)
Vue.use(attributeExpirationCalculation)
Vue.use(skillAttributeExpiration)
Vue.use(gradeSuppression)
Vue.use(dataConsolidation)
Vue.use(petDataConsolidation)
Vue.use(DataComparison)
Vue.use(energyWithdrawal)
Vue.use(ConversionOfMartialDamageAttackTimes)


// 如果是用params进行隐性传值,在返回上一层的时候需要下面的导航前置守卫帮助
// 如果是query显现传值则不需要导航前置守卫也能回到上一个页面  query好像会保存上一次传输的数据

// 导航前置守卫
// 当前浏览的文章
// let browseArticles = []
// // 当前作者
// let currentAuthor = ''
// router.beforeEach((to, from, next) => {
//   console.log('to:', to);
//   console.log('from:', from);
//   if ((from.name === '文章展示' && to.name === '作者主页') || (from.name === '安卓-文章展示' && to.name === '安卓-作者主页')) {
//     // 从文章展示前进到作者主页有特殊指令,通行后保存此次浏览记录
//     // 从文章展示后退到作者主页没有此特殊指令,则不保存记录,并且将文章作者传给作者主页面进行展示作者
//     if (to.params.forward) {
//       browseArticles.push(from.params.name)
//     } else {
//       to.params.strategyAuthor = currentAuthor
//       // to.params.strategyAuthor = from.params.data.strategyAuthor
//     }
//   }
//   if ((from.name === '作者主页' && to.name === '文章展示') || (from.name === '安卓-作者主页' && to.name === '安卓-文章展示')) {
//     // 如果是从文章展示进入的作者主页这一定要从作者主页返回到文章展示时对浏览记录进行一次次的删除读取
//     if (!to.params.hasOwnProperty('name')) {
//       to.params.name = browseArticles.pop()
//     }
//     // 从文章展示进入作者主页时,保留当前的作者以便路由返回
//     currentAuthor = from.params.strategyAuthor
//   }

//   if ((from.name === '作者主页' && to.name === '玩法展示') || (from.name === '安卓-作者主页' && to.name === '安卓-玩法展示')) {
//     currentAuthor = from.params.strategyAuthor
//   }

//   if ((from.name === '玩法展示' && to.name === '作者主页') || (from.name === '安卓-玩法展示' && to.name === '安卓-作者主页')) {
//     to.params.strategyAuthor = currentAuthor
//   }
//   next()
// })


//定义全局方法
Vue.directive('focus', (el) => {
  // 聚焦元素
  if (el.children.length) {
    for (let i = 0, j = el.children.length; i <= j; i++) {
      if (el.children[i]) {
        if (el.children[i].nodeName === 'INPUT') {
          el.children[i].focus()
        }
      }
    }
  }
})

// 用于检测是移动端还是PC端
// function equipment() {
//   let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
//   return flag
// }

// Vue.directive('focuss', {
//   inserted(el) {
//     el.focus()
//   }
// })

Vue.use(ElementUI);
Vue.use(Vant);
Vue.use(Lazyload);
Vue.use(VueClipboard);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')