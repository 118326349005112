exports.install = function (Vue) {
  Vue.prototype.energyWithdrawal = function (deduction, skillAttribute, characteristic) {
    // 本页面使用的都是对象深拷贝

    // 蓝量总值
    // console.log(deduction);
    // 扣篮的技能
    // console.log(skillAttribute);
    // 副本是否有回合回复效果
    // console.log(characteristic);

    // 数据叠加保存的数组
    let list = []

    // 备份最大蓝量值
    let energy = deduction
    let energyArry = []
    // 当前回合蓝量初始值
    let currentData = []

    // 备份数据,后面通过备份的数据计算
    let backupData = []
    // 对传过来的数据先做个备份
    let copySkillAttribute = []
    // 通过json转义达到对象深拷贝的效果==>不是引用地址值而是拿到具体数据
    skillAttribute.filter(item => backupData.push(JSON.parse(JSON.stringify(item))))
    skillAttribute.filter(item => copySkillAttribute.push(JSON.parse(JSON.stringify(item))))

    // 持续回合扣除百分比
    let continuousDeduction = 0
    // 持续回合扣除的基础值
    let continuousAdditional = 0
    // 持续回合扣除的具体数据
    let roundDeduction = 0
    let roundDeductionArry = []
    // 扣篮技能数组
    let dunkSkillArry = []
    let dunkSkills = []

    for (let i = 1; i <= 30; i++) {
      // 数据初始化
      roundDeduction = 0

      // 副本回合回复
      if (characteristic) {
        deduction = parseInt(deduction * 1.005)
      }
      // 对最大蓝量进行规范
      deduction = energy > deduction ? deduction : energy

      // 当前回合初始值
      currentData.push(deduction)

      // 从第二回合开始,向传递过来的属性值里面填充备份的数据==>模拟打副本时技能效果叠加
      if (i > 1) {
        copySkillAttribute.filter(item => {
          // 单开模式每回合增加数据
          if (!item.mode) {
            backupData.push(JSON.parse(JSON.stringify(item)))
          }
          // 混开模式单回合增加数据
          if (item.mode) {
            if ((!item.doubleRound && i % 2 !== 0) || (item.doubleRound && i % 2 === 0)) {
              backupData.push(JSON.parse(JSON.stringify(item)))
            } else {
              // 混开模式在双回合不会有数据填充,因此,扣除值也应该为0
              // 但是为了防止扣除值数据被打乱,这里填充一个扣除0%的数据值,持续一回合不会影响数据
              backupData.push({
                mode: true,
                additional: 0,
                round: 1,
                value: 0
              })
            }
          }
        })
      }

      // 持续回合扣除的值continuousDeduction
      roundDeduction = parseInt(energy * continuousDeduction) + continuousAdditional
      roundDeduction = deduction > roundDeduction ? roundDeduction : deduction
      deduction -= roundDeduction
      roundDeductionArry.push(roundDeduction)

      // 重置持续回合扣除
      continuousDeduction = 0
      // 重置持续回合扣除的基础值
      continuousAdditional = 0

      // 遍历传递过来的技能属性值
      backupData.filter((item) => {
        // 属性过期回合分配
        if (!item.overdue) {
          item.overdue = parseInt(item.round) + i - 1
          // 单回合数据使用
          if (item.round === 1) {
            // (item.additional || 0) 防止用户误删技能基础属性扣除值
            let deductionData = parseInt((item.value / 100) * energy) + (item.additional || 0)
            deductionData = deduction > deductionData ? deductionData : deduction
            deduction -= deductionData
            dunkSkillArry.push(deductionData)
          } else {
            // 单开模式的数据
            if (!item.mode) {
              let deductionData = parseInt((item.value / 100) * energy) + (item.additional || 0)
              deductionData = deduction > deductionData ? deductionData : deduction
              deduction -= deductionData
              dunkSkillArry.push(deductionData)
            } else
              // 混开模式的数据
              if (item.mode && (!item.doubleRound && i % 2 !== 0) || (item.doubleRound && i % 2 === 0)) {
                let deductionData = parseInt((item.value / 100) * energy) + (item.additional || 0)
                deductionData = deduction > deductionData ? deductionData : deduction
                deduction -= deductionData
                dunkSkillArry.push(deductionData)
              } else {
                dunkSkillArry.push(0)
              }
          }
        }

        // 持续回合的扣除值
        // 单开模式的数据
        if (!item.mode && i < item.overdue) {
          continuousDeduction = parseFloat(((continuousDeduction * 100 + item.value) / 100)).toFixed(3)
          // 持续回合扣除的基础值
          continuousAdditional += (item.additional || 0)
        }

        // 混开模式的数据
        if (item.mode && (!item.doubleRound && i % 2 !== 0) || (item.doubleRound && i % 2 === 0) && i < item.overdue) {
          continuousDeduction = parseFloat(((continuousDeduction * 100 + item.value) / 100)).toFixed(3)
          // 持续回合扣除的基础值
          continuousAdditional += (item.additional || 0)
        }
      })
      // 将回合剩余值保留到数组中 
      energyArry.push(deduction)

      if (deduction <= 0) {
        let index = 1, arryLength = skillAttribute.length
        for (let j = 0, len = dunkSkillArry.length; j < len; j++) {
          dunkSkills.push(`扣篮技能${index}扣除的蓝量为:${dunkSkillArry[j]}`)
          index++
          if (index > arryLength) index = 1
        }
        dunkSkills = againGroup(dunkSkills, arryLength)
        for (let j = 1; j <= i; j++) {
          list.push({
            round: `第【${j}】回合`,
            currentData: `当前回合初始值为:${currentData[j - 1]}`,
            continued: `持续回合扣除值:${roundDeductionArry[j - 1] ?? 0}`,
            dunkSkills: dunkSkills[j - 1],
            dataObject: `回合结束剩余值为:${energyArry[j - 1]}`
          })
          // }
        }
        break;
      }
      if (i === 30 && deduction > 0) {
        alert("客官莫要打趣小生了")
        return false
      }
    }
    return list

    // 切割数组
    function againGroup(data, num) {
      var result = [];
      for (var i = 0, len = data.length; i < len; i += num) {
        result.push(data.slice(i, i + num));
      }
      return result;
    }
  }
}